.modal {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.6);
  z-index: 99;
}

.modal .container {
  position: fixed;
  max-width: 600px;
  top: 15%;
  left: 0;
  right: 0;
  margin: 0 auto;
  padding: 4em 2rem;
  background-color: #fff;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.8);
}
.close {
  background-color: #f65835;
  border: 0;
  color: #fff;
  position: absolute;
  top: 15px;
  right: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 4px;
  border-radius: 5px;
}

.close svg {
  margin-right: 5px;
}

.modal h2 {
  text-align: center;
  font-size: 1.5em;
  margin-top: -30px;
}

.modal span {
  font-weight: bold;
  font-size: 1.2em;
  color: #121212;
}

.modal span i {
  font-weight: 400;
  margin-right: 1em;
  padding: 2px 8px;
  border-radius: 3px;
}

.row {
  display: flex;
  max-height: 42rem;
  padding: 20px;
  margin-top: -30px;
  justify-content: center;
  align-items: center;
  margin-bottom: 0.5em;
  text-align: justify;
}