.menu {
    background: #fff;
    border-radius: 50%;
    position: fixed;
    width: 60px;
    height: 60px;
    bottom: 25px;
    right: 25px;
    cursor: pointer;
    box-shadow: 0 0 0 0 #FFF9EC, 0 0 0 0 #FFF9EC;
    transition: box-shadow 1.1s cubic-bezier(0.19, 1, .22, 1);
}

.menu:hover {
    box-shadow: 0 0 0 8px #FFF9EC, 0 0 0 8px #FFF9EC;
}

.input-menu {
    display: none;
}

.hamburguer {
    position: relative;
    display: block;
    background: var(--color-padrao);
    width: 30px;
    height: 2px;
    top: 29px;
    left: 15px;
    transition: .5s ease-in-out;
}

.hamburguer:before,
.hamburguer:after {
    background: var(--color-padrao);
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    transition: .5s ease-in-out;
}

.hamburguer:before {
    top: -10px;
}

.hamburguer:after {
    bottom: -10px;
}

input:checked~label .menu {
    box-shadow: 0 0 0 130vw #fff, 0 0 0 130vh #fff;
}

input:checked~ul {
    opacity: 1;
}

input:checked~label .hamburguer {
    transform: rotate(45deg);
}

input:checked~label .hamburguer:before {
    transform: rotate(90deg);
    top: 0;
}

input:checked~label .hamburguer:after {
    transform: rotate(90deg);
    bottom: 0;
}

.ul-home {
    position: fixed;
    top: 35%;
    left: 50%;
    font-family: Arial, Helvetica, sans-serif;
    transform: translate(-50%, -50%);
    list-style: none;
    opacity: 0;
    transition: .25s .1s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.ahome {
    display: block;
    margin-bottom: 5px;
    color: var(--color-padrao);
    font-size: 40px;
    text-decoration: none;
}

.menu-home {
    position: absolute;
}

.loading {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.sobre {
    padding: 20px;
    margin: 20px;
    border-radius: 10px;
    border-left: 5px solid var(--color-padrao);
    color: var(--color-padrao);
    font-weight: 500;
    background-color: #FFF9EC;
    transition: all 0.3s ease-out;
    box-shadow: 7px 7px 13px 0px rgba(50, 50, 50, 0.25);
}

.sobre:hover {
    transform: translateY(-10px);
    cursor: pointer;
  }

.textosobre {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-size: 1.9rem;
    padding: 10px;
    text-align: justify;
    line-height: 30px;
    font-family: Arial, Helvetica, sans-serif;
}

#sub {
    text-decoration: underline;
}

.imgmay {
    background-color: #FFF;
    border-radius: 150px;
    box-shadow: 7px 7px 13px 0px rgba(50, 50, 50, 0.25);
    width: 260px;
    height: 260px;
    border-left: 10px solid var(--color-padrao);
    transition: all 0.3s ease-out;
}

.texto1::first-letter {
    font-size: 10rem;
    font-weight: bold;
    color: var(--color-padrao);
    padding-bottom: 10px;
    font-family: Arial, Helvetica, sans-serif;
}

.texto2 {
    padding-top: 10px;
    font-family: Arial, Helvetica, sans-serif;
}

.textorodape {
    display: flex;
    align-items: flex-end;
    justify-content: end;
    margin-top: 30px;
    font-family: Arial, Helvetica, sans-serif;
}

