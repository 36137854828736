.loading {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.pulse {
  animation: pulse 0.6s infinite;
  margin: 0 auto;
  display: table;
  animation-direction: alternate;
  -webkit-animation-name: pulse;
  animation-name: pulse;
}

@-webkit-keyframes pulse {
  0% {
    -webkit-transform: scale(1);
    -webkit-filter: brightness(100%);
  }
  100% {
    -webkit-transform: scale(1.1);
    -webkit-filter: brightness(100%);
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
    filter: brightness(100%);
  }
  100% {
    transform: scale(1.1);
    filter: brightness(100%);
  }
}

.card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  max-width: 300px;
  margin: auto;
  text-align: center;
  font-family: Arial, Helvetica, sans-serif;
}

.card-depoimentos {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  max-width: 300px;
  margin: auto;
  text-align: center;
  font-family: Arial, Helvetica, sans-serif;
}

.card-depoimentos button {
  border: none;
  outline: 0;
  padding: 12px;
  color: white;
  background-color: #000;
  text-align: center;
  cursor: pointer;
  width: 100%;
  font-size: 18px;
}

.card-depoimentos button:hover {
  opacity: 0.7;
}

.cards {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}


.row {
  display: flex;
  margin-top: 10px;
  flex-wrap: wrap;
  justify-content: center;
}

.row-depoimento {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.card-depoimentos {
  max-width: 300px;
  margin: auto;
  text-align: center;
  font-family: Arial, Helvetica, sans-serif;
  flex: 1 1 300px;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: #FFF;
  border-radius: 100px;
  box-shadow: 7px 7px 13px 0px rgba(50, 50, 50, 0.25);
  padding: 15px;
  margin: 10px;
  height: 290px;
  transition: all 0.3s ease-out;
}

.card-depoimentos:hover {
  transform: translateY(-10px);
  cursor: pointer;
}

.card-depoimentos p {
  padding-bottom: 100px;
  text-align: center;
  color: #575555;
  font-size: 1.2rem;
}

.card-depoimentos .regiao {
  padding-bottom: 5px;
  text-align: center;
  color: #575555;
  font-size: 12px;
}

.card-depoimentos h2 {
  font-size: 1.6rem;
  color: #575555;
}

.image {
  margin-top: -10px;
  border-radius: 50px;
  max-width: 90px;
  max-height: 90px;
}

/* -----------------------ESTILIZANDO CARD CONSULTORIA---------------- */
#home .row-card a {
  text-decoration: none;
}

#home .row-card {
  display: flex;
  margin-top: 0px;
  flex-wrap: wrap;
  justify-content: center;
}

.card-consultoria {
  flex: 1 1 400px; /* REDIMENCIONA OS CARDS */
  background-color: #FFF9EC;
  border-radius: 20px;
  box-shadow: 7px 7px 13px 0px rgba(50, 50, 50, 0.22);
  margin: 10px;
  width: 350px;
  height: 400px;
  transition: all 0.3s ease-out;
  overflow: hidden;
}

.card-consultoria:hover {
  transform: translateY(-10px);
  cursor: pointer;
}

#home .card-consultoria p {
  margin-top: -3rem;
  padding: 4px;
  text-align: justify;
  color: #575555;
  font-size: 1.6rem;
}

#home .card-consultoria h2 {
  padding: .5rem;
  color: var(--color-padrao);
  text-align: center;
}

.texto-boas-vindas {
  font-size: 2rem;
  font-weight: 600;
  color: var(--color-padrao);
  padding-bottom: 0px;
  font-family: Arial, Helvetica, sans-serif;
}

.image-logo-consultoria {
  width: 100%;
  height: 100%;

}

/*----------------------------------------------------------------------*/

.image-logo {
  width: 100px;
  height: 180px;
  max-width: 200px;
  max-height: 200px;
  display: block;
}

.padrao {
  border-left: 5px solid var(--color-padrao);
}


.linha {
  border: 0;
  height: 1px;
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0));
}

.btn-menu a {
  text-decoration: none;
  color: var(--color-padrao);
  border: none;
  background: #000;
}

.btn-book {
  border: none;
  background: #FFF9EC;
}

.btn-menu {
  margin: 1em;
  padding: 0.2rem;
  height: 32px;
  border: 0;
  position: relative;
  color: var(--color-padrao);
  text-decoration: none;
  border-radius: 5px;
  font-family: Arial, Helvetica, sans-serif;
  font: 'Arial, Helvetica, sans-serif';
  color: var(--color-padrao);
  font-size: 1.5em;
  cursor: pointer;
}

.btn-menu:hover {
  background-color: #FFDCCC;
}


/* LINK WHATSAPP */
.whatsapp-link {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 40px;
  right: 40px;
  background-color: rgb(37, 211, 102);
  color: rgb(255, 255, 255);
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  box-shadow: rgb(136 136 136) 1px 1px 2px;
  z-index: 1000;
  display: block;
}

.fa-whatsapp {
  margin-top: 16px;
}

/* ESTILIZANDO SLIDES */

.slider {
  margin: 0 auto;
  width: 800px;
  height: 400px;
  overflow: hidden;
}

.slides {
  width: 400%;
  height: 400px;
  display: flex;
}

.slides input {
  display: none;
}

.slide {
  width: 25%;
  position: relative;
  transition: 2s;
}

.slide img {
  width: 800px;
}

.navegacao-manual {
  position: absolute;
  width: 800px;
  margin-top: -40px;
  display: flex;
  justify-content: center;
}

.manual-btn {
  border: 2px solid #FFF9EC;
  padding: 5px;
  border-radius: 10px;
  cursor: pointer;
  transition: 1s;
}


.manual-btn:not(:last-child) {
  margin-right: 15px;
}

.manual-btn:hover {
  background-color: #FFF9EC;
}

#radio1:checked~.primeira {
  margin-left: 0;
}

#radio2:checked~.primeira {
  margin-left: -25%;
}

#radio3:checked~.primeira {
  margin-left: -50%;
}

#radio4:checked~.primeira {
  margin-left: -75%;
}

#radio5:checked~.primeira {
  margin-left: -100%;
}

#radio6:checked~.primeira {
  margin-left: -125%;
}

#radio7:checked~.primeira {
  margin-left: -150%;
}

#radio8:checked~.primeira {
  margin-left: -175%;
}

#radio9:checked~.primeira {
  margin-left: -200%;
}

#radio10:checked~.primeira {
  margin-left: -225%;
}

#radio11:checked~.primeira {
  margin-left: -250%;
}

#radio12:checked~.primeira {
  margin-left: -275%;
}

.navegacao-auto div {
  border: 2px solid var(--color-padrao);
  padding: 5px;
  border-radius: 10px;
  cursor: pointer;
  transition: 1s;
}

.navegacao-auto {
  position: absolute;
  width: 800px;
  margin-top: 360px;
  display: flex;
  justify-content: center;
}

.navegacao-auto div:not(:last-child) {
  margin-right: 15px;
}

#radio1:checked~.navegacao-auto .auto-btn1 {
  background-color: #FFF9EC;
}

#radio2:checked~.navegacao-auto .auto-btn2 {
  background-color: #FFF9EC;
}

#radio3:checked~.navegacao-auto .auto-btn3 {
  background-color: #FFF9EC;
}

#radio4:checked~.navegacao-auto .auto-btn4 {
  background-color: #FFF9EC;
}

#radio5:checked~.navegacao-auto .auto-btn5 {
  background-color: #FFF9EC;
}

#radio6:checked~.navegacao-auto .auto-btn6 {
  background-color: #FFF9EC;
}

#radio7:checked~.navegacao-auto .auto-btn7 {
  background-color: #FFF9EC;
}

#radio8:checked~.navegacao-auto .auto-btn8 {
  background-color: #FFF9EC;
}

#radio9:checked~.navegacao-auto .auto-btn9 {
  background-color: #FFF9EC;
}

#radio10:checked~.navegacao-auto .auto-btn10 {
  background-color: #FFF9EC;
}

#radio11:checked~.navegacao-auto .auto-btn11 {
  background-color: #FFF9EC;
}

#radio12:checked~.navegacao-auto .auto-btn12 {
  background-color: #FFF9EC;
}

.footer {
  margin-top: 20px;
  justify-content: center;
  background-color: #FFF9EC;

}

.footer-menu {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 20px;
}

.footer-menu h2 {
  font-size: 1.7rem;
  padding-top: 20px;
  font-family: Arial, Helvetica, sans-serif;
}

@media (max-width: 700px) {
  .slider {
    margin: 0 auto;
    width: 350px;
    height: 200px;
    overflow: hidden;
  }

  .slides {
    width: 400%;
    height: 200px;
    display: flex;
  }

  .slide {
    width: 25%;
    position: relative;
    transition: 2s;
  }

  .slide img {
    width: 350px;
  }

  .navegacao-manual {
    position: absolute;
    width: 300px;
    margin-top: -30px;
    display: flex;
    justify-content: center;
  }

  .manual-btn {
    border: 2px solid #FFF9EC;
    padding: 2px;
    border-radius: 10px;
    cursor: pointer;
    transition: 1s;
  }

  .navegacao-auto div {
    border: 2px solid var(--color-padrao);
    padding: 2px;
    border-radius: 10px;
    cursor: pointer;
    transition: 1s;
  }

  .navegacao-auto {
    width: 300px;
    margin-top: 170px;
  }
}

#page-email {
  padding-top: 30px;
}

#page-email main {
  font-family: Arial, Helvetica, sans-serif;
  background: var(--color-box-base);
  width: 90%;
  max-width: 75rem;
  border-radius: 0.8rem;
  margin: 0 auto 3.2rem;
  padding-top: 0.1rem;
  overflow: hidden;
}

#page-email main fieldset {
  border: 0;
  padding: 0 2.4rem;
}

#page-email main form {
  width: 100%;
}

#page-email main fieldset legend {
  font: 700 2.4rem 'Arial, Helvetica, sans-serif';
  color: var(--color-text-title);
  margin-bottom: 2.4rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 1.6rem;
  border-bottom: 1px solid var(--color-line-in-white);
}

#page-email main fieldset legend button {
  background: none;
  border: 0;
  color: var(--color-primary);
  font: 700 1.6rem 'Arial, Helvetica, sans-serif';
  cursor: pointer;
  transition: color 0.2s;
}

#page-email main fieldset legend button:hover {
  color: var(--color-primary-dark);
}

#page-email main label {
  color: var(--color-text-complement);
}

#page-email main footer {
  padding: 2rem;
  margin-top: 0 auto;
}


#page-email main footer button {
  width: 100%;
  height: 4.6rem;
  background: var(--color-primary);
  color: var(--color-button-text);
  border: 0;
  border-radius: 0.8rem;
  cursor: pointer;
  font: 700 1.9rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  transition: background-color 0.2s;
  margin-top: 3.2rem;
}

#page-email main footer button:hover {
  background: var(--color-primary);
}

@media (min-width: 700px) {
  #page-email {
    max-width: 100vw;
  }

  #page-email .page-header .header-content {
    margin-bottom: 0;
  }

  #page-email main fieldset {
    padding: 0 6.4rem;
  }

  #page-email main .schedule-item {
    display: grid;
    grid-template-columns: 1fr;
    column-gap: 1rem;
  }

  #page-email main .schedule-item .input-block {
    margin-top: 0 !important;
  }

  #page-email main footer {
    padding: 2rem 6.4rem;
    display: flex;
    align-items: center;
    justify-content: end;
  }

  #page-email main footer p {
    justify-content: space-between;
  }

  #page-email main footer button {
    width: 10rem;
    margin-top: 0;
  }
}

.input-block {
  position: relative;
}

/*Pega os inputs a seguir*/
.input-block+.input-block {
  margin-top: 1.4rem;
}

.input-block label {
  font-size: 1.4rem;
}

.input-block input {
  width: 100%;
  height: 3.5rem;
  margin-top: 0.8rem;
  border-radius: 0.8rem;
  background: var(--color-input-background);
  border: 1px solid var(--color-line-in-white);
  outline: 0;
  padding: 0 1.6rem;
  font: 1.6rem 'Arial, Helvetica, sans-serif';
}

.input-block:focus-within::after {
  width: calc(100% - 3.2rem);
  height: 2px;
  content: "";
  background: var(--color-primary-light);
  position: absolute;
  left: 1.6rem;
  right: 1.6rem;
  bottom: 0;
}