.select-block {
    position: relative;
}


/*Pega os selects a seguir*/

.select-block+.select-block {
    margin-top: 1.4rem;
}

.select-block label {
    font-size: 1.4rem;
    font: 1.5rem 'Arial, Helvetica, sans-serif';
}

.select-block select {
    width: 100%;
    height: 3.5rem;
    margin-top: 0.8rem;
    border-radius: 0.8rem;
    background: var(--color-input-background);
    border: 1px solid var(--color-line-in-white);
    outline: 0;
    padding: 0 1.6rem;
    font: 1.6rem 'Arial, Helvetica, sans-serif';
}

.select-block:focus-within::after {
    width: calc(100% - 3.2rem);
    height: 2px;
    content: "";
    background: var(--color-primary-light);
    position: absolute;
    left: 1.6rem;
    right: 1.6rem;
    bottom: 0;
}