:root {
    font-size: 60%;
    --color-background: #f0f0f7;
    --color-primary-lighter: #f068f0;
    --color-primary-light: #ee43c9;
    /* --color-primary: #f38af3; */
    --color-primary: #C800A1;
    --color-primary-dark: #774dd6;
    --color-primary-darker: #020202;
    --color-secundary: #04d361;
    --color-secundary-dark: #04bf58;
    --color-title-in-primary: #ffffff;
    --color-text-in-primary: #ffffff;
    --color-text-title: #32264d;
    --color-text-complement: #9c98a6;
    --color-text-base: #6a6180;
    --color-line-in-white: #e6e6f0;
    --color-input-background: #f8f8fc;
    --color-button-text: #ffffff;
    --color-box-base: #ffffff;
    --color-box-footer: #fafafc;
    --color-padrao: #C800A1
}