.container-center {
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login {
  width: 600px;
  padding-bottom: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.login-area {
  display: flex;
  justify-content: center;
  width: 100%;
}

.login-area img {
  margin-top: 50px;
  width: 200px;
  height: 200px;
}

form {
  margin-top: 1.5em;
  width: 90%;
  display: flex;
  flex-direction: column;
}

form h1 {
  text-align: center;
  margin-bottom: 0.5em;
  color: #181e2e;
}

.login input {
  margin-bottom: 0.5em;
  height: 35px;
  border: 0;
  border-radius: 7px;
  padding: 10px;
  font-size: 15px;
  background-color: #fff;
}

form button {
  height: 35px;
  border: 0;
  text-align: center;
  border-radius: 7px;
  background-color: #bf0280;
  color: #fff;
  font-size: 1.3em;
}

.login a {
  margin: 1.5em 0;
  color: #000;
  cursor: pointer;
}

.loading {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}