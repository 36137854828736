.dashboard {
   display: flex;
   justify-content: center;
   align-items: center;
   flex-direction: column;
}
.dashboard span {
   margin: 2em 0;
   font-weight: 600;
   font-size: 1.2em;
}

.new {
   float: right;
   margin-bottom: 1.5em;
   background-color: #bf0280;
   color: #FFF;
   border: 0;
   padding: .3em;
   display: flex;
   justify-content: center;
   align-items: center;
   font-weight: 600;
   font-size: 1.1em;
   border-radius: 6px;
}

.new svg {
   margin-right: 4px;
}

.new:hover{
   background-color: #f10ba5;
   transform: scale(1.1);
}

table {
   border: 1px solid #ccc;
   border-collapse: collapse;
   margin: 0;
   padding: 0;
   width: 100%;
   table-layout: fixed;
}

table caption {
   font-size: 1.5m;
   margin: .5em 0 .75em;
}
table tr {
   background-color: #F8F8F8;
   border: 1px solid #ccc;
   padding: .35em;
}
table th,
table td {
   padding: .25em;
   text-align: center;
}

table th {
   font-size: .90em;
   letter-spacing: .1em;
   text-transform: uppercase;
}
table td .action {
   border: 0;
   padding: 5px;
   margin-right: 2px;
   align-items: center;
   display: inline-block;
   border-radius: 4px;
}

table td .action svg {
   vertical-align: middle;
}

table td .badge {
   padding: 1px;
   border-radius: 3px;
   color: #FFF;
}

.btn-more {
   margin: 1.5em 0;
   padding: .4em 0.6em;
   height: 30px;
   border: 0;
   border-radius: 5px;
   background-color: #bf0280;
   color: #FFF;
   font-size: 1.1em;
}

/* Responsividade table */
@media screen and (max-width: 600px){
   table {
      border: 0;
   }

   table caption {
      font-size: 1.3em;
   }

   table thead {
      border: none;
      clear: rect(0 0 0 0 );
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 10px;
      position: absolute;
      width: 1px;
   }

   table tr {
      border-bottom: 3px solid #ddd;
      display: block;
      margin-bottom: .65em;
   }
   table td {
      border-bottom: 3px solid #ddd;
      display: block;
      font-size: .8em;
      text-align: right;
   }

   table td::before{
      content: attr(data-label);
      float: left;
      font-weight: bold;
      text-transform: uppercase;
   }

   table td:last-child{
      border-bottom: 0;
   }
}
#relatoriobiotipo{
   display: flex;
   flex-direction: column;
   border-radius: 5px;
   padding: .1em;
   align-items: center;
   margin-bottom: 1em;
 }

 .box {
   transition: box-shadow 0.2s;
   width: 100%;
   height: 75%;
   margin: 10px;
   padding: 20px;
   border-radius: 10px;
   background: #fff;
   box-shadow: 0 0 4px rgba(19, 18, 18, 0.2);
 }
 .box:hover {
   background: #fff;
   box-shadow: 0 0 10px rgba(19, 18, 18, 0.4);
 }

 #relatoriobiotipo fieldset {
    border: 0;
    padding: 0 0.4rem;
}

#relatoriobiotipo fieldset legend {
    font: 700 2.2rem 'Arial, Helvetica, sans-serif';
    color: var(--color-text-title);
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    border-bottom: 1px solid var(--color-line-in-white);
}

#relatoriobiotipo .row {
   margin-top: -30px;
   margin-bottom: 10px;
}