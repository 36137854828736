form textarea {
   height: 105px;
   resize: none;
}

form input[type="radio"]{
   margin: 15px 0;
}
form input[type="radio"]:not(:first-child){
   margin-left: 15px;
}

.status span {
   padding-left: .5em;
   font-size: 1.3em;
}