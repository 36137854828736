html {
  font-size: 62.5%; /* 10px */
    scroll-behavior: smooth;
}

body {
  margin: 0;

  font-family: Arial, Helvetica, sans-serif;
  font-size: 1.4rem;
}

.layout-cabecalho {
  margin-bottom: 60px;
  height: 70px;
  line-height: 70px;
  border-bottom: 1px solid #FFF;
  width: 100%;
  background-color: var(--color-padrao);
}

.layout-cabecalho--home {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9999;
}

.layout-planos {
  padding: 80px 0;
}

.layout-depoimento {
  padding: 80px 0;
  background-color: #e7e7e7;
}

.layout-rodape {
  padding: 40px 0;

  background-color: #484848;

  color: #fff;
  text-align: center;
}

@media(max-width: 749px) {
  .layout-planos .row > div:not(.ultima-coluna) {
    margin-bottom: 40px;
  }
}

.layout-assinatura {
  padding: 40px 0;
}

/*--------------------*\
  BOTAO
\*--------------------*/
.botao {
  display: inline-block;
  padding: 0.8em 1.2em;

  /* background-color: #bbb; */
  color: #fff;
  border: none;

  line-height: normal;
  font-size: 1em;
  text-decoration: none;
}

.botao:hover, .botao:focus {
  /* background-color: #aaa; */
  color: #fff;

  text-decoration: none;
}

.botao--assinar, .botao--loja {
  border-radius: 5px;
}

/*--------------------*\
  NAVEGACAO
\*--------------------*/
.barra--assinatura {
  text-align: center;
}

.barra__menu {
  float: right;
}

.barra img {
  margin-top: -25px;
}

.barra__menu .menu-barra .menu__item a {
  padding: 5px;
  font-size: large;
  border-radius: 5px;
  color: #FFF;
  text-decoration: none;
}
.barra__menu .menu-barra .menu__item a:hover {
  background-color: #FFF;
  color: var(--color-padrao);

 /*  var(--color-padrao)

  #FB6376
 
  #F6B1A6
 
  #FFDCCC
 
  #FFF9EC */
}


/*--------------------*\
  BOTAO-CHAVEADOR
\*--------------------*/
.botao-chaveador {
  width: 24px;
  height: 24px;
  border: 0;

  background: none;
  background-image: url("./imagens/bars.png");

  outline: none;
}

@media(min-width: 992px) {
  .botao-chaveador {
    display: none;
  }
}


/*--------------------*\
  MENU
\*--------------------*/
.menu-barra {
  color: #c20303;
  margin: 0;

  font-size: 1.7rem;

  list-style: none;
}

@media(max-width: 991px) {

  .menu-barra {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;

    display: none;
    width: 200px;
    padding: 20px;
    border-right: 1px solid #FFF;

    background-color: var(--color-padrao);
  }

  .menu--exibindo {
    display: block;
  }

  .menu__item {
    display: block;

    line-height: 3;
  }

  .menu__item--botao {
    margin-top: 20px;
  }

}

@media(min-width: 992px) {

  .menu__item {
    display: inline-block;
    padding-right: 20px;
  }

  .menu__item:last-of-type {
    padding-right: 0px;
  }

}

