.modal {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.6);
  z-index: 99;
}

.tela-dados {
  position: fixed;
  text-align: center;
  max-width: 650px;
  height: 80%;
  top: 12%;
  left: 15px;
  right: 15px;
  border-radius: 5px;
  margin: 0 auto;
  text-align: justify;
  padding: 4em 3rem 0rem 3em;
  text-overflow: '';
  background-color: #fff;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.8);
  overflow: scroll;
  overflow-x: hidden;
}

.close {
  background-color: var(--color-padrao);
  border-radius: 4px;
}

.row {
  margin-top: 2px;
}

.close svg {
  margin-right: 1px;
}

.modal h2 {
  margin-bottom: 1.2em;
  font-size: 2em;
}

.modal span {
  font-weight: bold;
  font-size: 1.1em;
  color: #121212;
}

.modal span p {
  font-weight: 500;
}

.modal p {
  padding-top: 0.5em;
  white-space: pre-wrap;
}

.modal .detalhes {
  font-size: 2.5rem;
  font-weight: 200;
}

.modal a {
  text-decoration: none;
}

.botao-card a {
  width: 300px;
  height: 4rem;
  background: var(--color-primary);
  color: var(--color-button-text);
  border: 0;
  border-radius: 0.8rem;
  cursor: pointer;
  font: 700 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.2s;
  box-shadow: 7px 7px 13px 0px rgba(50, 50, 50, 0.10);
  margin-top: 1.5rem;
}

.botao-card a:hover {
  transform: translateY(-2px);
  box-shadow: 7px 7px 13px 0px rgba(50, 50, 50, 0.50);
}