.relatorio{
    display: flex;
    background-color: #f8f8f8;
    border-radius: 5px;
    padding: .8em;
    align-items: center;
    margin-bottom: 1em;
  }

  .box {
    transition: box-shadow 0.2s;
    width: 95%;
    height: 75%;
    margin: 10px;
    padding: 20px;
    border-radius: 10px;
    background: #fff;
    box-shadow: 0 0 4px rgba(19, 18, 18, 0.2);
  }
  .box:hover {
    background: #fff;
    box-shadow: 0 0 10px rgba(19, 18, 18, 0.4);
    cursor: pointer;
  }

  .grid {
    display: flex;
    flex-direction: row;
  }