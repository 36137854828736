.container {
  padding: 2px 16px;
}

.barra {
  text-align: center;
}

.barra img {
  margin-top: -25px;
}

.action {
  margin-top: 100px;
}

/* -----------------------ESTILIZANDO CARD CONSULTORIA---------------- */
.row-cards {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.card-card {
  flex: 1 1 300px;
  margin-top: 100px;

  border-radius: 15px;
  margin: 15px;
  width: 390px;
  height: 400px;
  overflow: hidden;
  transition: all 0.2s ease-out;
  background-color: #FFF9EC;
  box-shadow: 7px 7px 13px 0px rgba(50, 50, 50, 0.10);
}

.card-card:hover {
  cursor: pointer;
  transform: translateY(-15px);
  box-shadow: 7px 7px 13px 0px rgba(50, 50, 50, 0.50);
}

.image-card {
  max-width: 100%;
  height: 100%;
}

.padrao-borda {
  border-left: 5px solid var(--color-padrao);
}

/*----------------------------------------------------------------------*/
.btn-book {
  border: none;
  background: #FFF9EC;
}

.btn-menu {
  margin: 1em;
  padding: 0.2rem;
  height: 32px;
  border: 0;
  position: relative;
  color: var(--color-padrao);
  text-decoration: none;
  border-radius: 5px;
  font-family: Arial, Helvetica, sans-serif;
  font: 'Arial, Helvetica, sans-serif';
  color: var(--color-padrao);
  font-size: 1.5em;
  cursor: pointer;
}

.btn-menu:hover {
  background-color: #FFDCCC;
}


/* LINK WHATSAPP */
.whatsapp-link {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 40px;
  right: 40px;
  background-color: #25d366;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  box-shadow: 1px 1px 2px #888;
  z-index: 1000;
}

.fa-whatsapp {
  margin-top: 16px;
}

.button-compra {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: .5em;
  width: 250px;
  max-width: 320px;
  height: 35px;
  border: 0;
  font-style: normal;
  font-weight: bold;
  border-radius: 5px;
  background-color: var(--color-padrao);
  color: #FFF;
  font-size: 1.5em;
  position: absolute;
  top: 90%;
}

.button-compra:hover {
  transform: scale(1.1);
  transition: all 0.5s;
  text-decoration: none;
  color: #FFF;
}

.de {
  display: flex;
  justify-content: center;
  align-items: baseline;
  text-align: center;
  position: absolute;
  top: 71%;
  font-size: 1em;
  font-weight: 400;
  color: red;
}

.por {
  display: flex;
  justify-content: center;
  align-items: baseline;
  text-align: center;
  position: absolute;
  top: 84%;
  font-size: 1.2em;
  font-weight: 400;
  color: blue;
}

.price {
  display: flex;
  justify-content: center;
  align-items: baseline;
  text-align: center;
  position: absolute;
  top: 74%;
}


.valor {
  font-size: 45px;
  color: var(--color-padrao);
  padding-left: 4px;
  font-weight: 700;
}

.centavos {
  font-size: 27px;
  color: var(--color-padrao);
  font-weight: 600;
}

.parcela {
  font-size: 20px;
  color: var(--color-padrao);
  font-weight: 600;
}