.dashboard {
   display: flex;
   justify-content: center;
   align-items: center;
   flex-direction: column;
}
.dashboard span {
   margin: 2em 0;
   font-weight: 600;
   font-size: 1.2em;
}

.content table {
   border: 1px solid #ccc;
   border-collapse: collapse;
   margin: 0;
   padding: 0;
   width: 100%;
   table-layout: fixed;
}

.content table caption {
   font-size: 1.5m;
   margin: .5em 0 .75em;
}
.content table tr {
   background-color: #F8F8F8;
   border: 1px solid #ccc;
   padding: .35em;
}
.content table th,
table td {
   padding: .25em;
   text-align: center;
}

.content table th {
   font-size: .90em;
   letter-spacing: .1em;
   text-transform: uppercase;
}
.content table td .action {
   border: 0;
   padding: 5px;
   margin-right: 2px;
   align-items: center;
   display: inline-block;
   border-radius: 4px;
}

.content table td .action svg {
   vertical-align: middle;
}

.content table td .badge {
   padding: 1px;
   border-radius: 3px;
   color: #FFF;
}

/* Responsividade table */
@media screen and (max-width: 600px){
   table {
      border: 0;
   }

   table thead {
      border: none;
      clear: rect(0 0 0 0 );
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 10px;
      position: absolute;
      width: 1px;
   }

   table tr {
      border-bottom: 3px solid #ddd;
      display: block;
      margin-bottom: .65em;
   }
   table td {
      border-bottom: 3px solid #ddd;
      display: block;
      font-size: .8em;
      text-align: right;
   }

   table td::before{
      content: attr(data-label);
      float: left;
      font-weight: bold;
      text-transform: uppercase;
   }

   table td:last-child{
      border-bottom: 0;
   }
}
