
  .card-ebooks button:hover {
    opacity: 0.7;
  } 
  
  /* -----------------------ESTILIZANDO CARD CONSULTORIA---------------- */
  .row-card-ebooks {
    display: flex;
    margin-top: -80px;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .card-ebooks {
    flex: 1 2 300px;
    display: flex;
    align-items: center;
    flex-direction: column;
    background-color: #FFF9EC;
    border-radius: 20px;
    box-shadow: 7px 7px 13px 0px rgba(50, 50, 50, 0.20);
    padding: 0 10px 100px;
    margin: 20px;
    width: 300px;
    height: 550px;
    transition: all 0.3s ease-out;
    overflow: hidden;
  }
  
  .card-ebooks {
    transform: translateY(-1px);
    box-shadow: 7px 7px 13px 0px rgba(50, 50, 50, 0.60);
  }
   
  .card-ebooks p {
    display: flex;
    justify-content: center;
    align-items: baseline;
    text-align: center;
    position: absolute;
    text-align: justify;
    color: #575555;
    font-size: 1.6rem;
    margin-left: 5px;
    margin-right: 5px;
    top: 55%;
  }

  .card-ebooks h2 {
    padding: 0rem;
    color: var(--color-padrao);    
    text-align: center;
  }

  .image-logo-ebooks {
    height: 55%;
    max-width: 150%;
  }

  /*----------------------------------------------------------------------*/
.btn-book {
  border: none;
  background: #FFF9EC;
}

.btn-menu {
  margin: 1em;
  padding:  0.2rem;
  height: 32px;
  border: 0;
  position: relative;
  color: var(--color-padrao);
  text-decoration: none; 
  border-radius: 5px;
  font-family: Arial, Helvetica, sans-serif;
  font: 'Arial, Helvetica, sans-serif';
  color: var(--color-padrao);
  font-size: 1.5em;
  cursor: pointer;
}
.btn-menu:hover {
 background-color: #FFDCCC;
}


/* LINK WHATSAPP */
.whatsapp-link {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 40px;
  right: 40px;
  background-color: #25d366;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  box-shadow: 1px 1px 2px #888;
  z-index: 1000;
}

.fa-whatsapp {
  margin-top: 16px;
}

.footer {
  margin-top: 20px;
  justify-content: center;
  background-color: #FFF9EC;

}

.footer-menu {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 20px;
}  

.footer-menu h2 {
  font-size: 1.7rem;
  padding-top: 20px;
  font-family: Arial, Helvetica, sans-serif;
}

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: .5em;
  width: 250px;
  max-width: 320px;
  height: 35px;
  border: 0;
  font-style: normal;
  font-weight: bold;
  border-radius: 5px;
  background-color: var(--color-padrao);
  color: #FFF;
  font-size: 1.5em;
  position: absolute;
  top: 90%;
}


.de {
  display: flex;
  justify-content: center;
  align-items: baseline;
  text-align: center;
  position: absolute;
  top: 71%;
  font-size: 1em;
  font-weight: 400;
  color: red;
}

.por {
  display: flex;
  justify-content: center;
  align-items: baseline;
  text-align: center;
  position: absolute;
  top: 84%;
  font-size: 1.2em;
  font-weight: 400;
  color: blue;
}

.price {
  display: flex;
  justify-content: center;
  align-items: baseline;
  text-align: center;
  position: absolute;
  top: 74%;
}

.button:hover { 
  transform: scale(1.1); 
  transition: all 0.5s; }

.valor {
  font-size: 45px; color: var(--color-padrao);
  padding-left: 4px;
  font-weight: 700;
}
.centavos {
  font-size: 27px; color: var(--color-padrao);
  font-weight: 600;
}
.parcela {
  font-size: 20px; color: var(--color-padrao);
  font-weight: 600;
}



