.select-blocks {
    position: relative;
}


/*Pega os selects a seguir*/

.select-blocks+.select-blocks {
    margin-top: 1.4rem;
}

.select-blocks label {
    font-size: 1.4rem;
}

.select-blocks select {
    width: 100%;
    height: 4rem;
    margin-top: 0.8rem;
    border-radius: 0.8rem;
    background: var(--color-input-background);
    border: 1px solid var(--color-line-in-white);
    outline: 0;
    padding: 0 0.8rem;
    font: 1.6rem 'Arial, Helvetica, sans-serif';
}
