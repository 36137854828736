#page-cores-form main form {
    width: 100%;
}

#page-cores-form .page-header .header-content {
    margin-bottom: 6.4rem;
}

#page-cores-form main {
    font-family: Arial, Helvetica, sans-serif;
    background: var(--color-box-base);
    width: 95%;
    max-width: 80rem;
    border-radius: 0.8rem;
    margin: 0 auto 3.2rem;
    padding-top: 0.5rem;
    overflow: hidden;
}

#page-cores-form main fieldset {
    border: 0;
    padding: 0 2.4rem;
}

#page-cores-form main fieldset+fieldset {
    margin-top: 3rem;
}

#page-cores-form main fieldset legend {
    font: 700 2.2rem 'Arial, Helvetica, sans-serif';
    color: var(--color-text-title);
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding-bottom: 1rem;
    border-bottom: 1px solid var(--color-line-in-white);
}

#page-cores-form main fieldset legend button {
    background: none;
    border: 0;
    color: var(--color-primary);
    font: 700 1.6rem 'Arial, Helvetica, sans-serif';
    cursor: pointer;
    transition: color 0.2s;
}

#page-cores-form main fieldset legend button:hover {
    color: var(--color-primary-dark);
}

#page-cores-form main fieldset .input-block+.textarea-block,
#page-cores-form main fieldset .select-block+.input-block {
    margin-top: 1.6rem;
}

#page-cores-form main label {
    color: var(--color-text-complement);
}

#page-cores-form main footer {
    padding: 4rem 2.4rem;
    background: var(--color-box-footer);
    border-top: 1px solid var(--color-line-in-white);
    margin-top: 4rem;
}

#page-cores-form main footer p {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.4rem;
    line-height: 2.4rem;
    font: 1.8rem 'Arial, Helvetica, sans-serif';
    color: var(--color-text-complement);
}

#page-cores-form main footer p img {
    margin-right: 1rem;
}

#page-cores-form main footer button {
    width: 100%;
    height: 4rem;
    background: var(--color-primary);
    color: var(--color-button-text);
    border: 0;
    border-radius: 0.8rem;
    cursor: pointer;
    font: 700 1.9rem;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    transition: background-color 0.2s;
    margin-top: 3.2rem;
}

#page-cores-form main footer button:hover {
    background: var(--color-primary);
}

@media (min-width: 700px) {
    #page-cores-form {
        max-width: 100vw;
    }

    #page-cores-form .page-header .header-content {
        margin-bottom: 0;
    }

    #page-cores-form main fieldset {
        padding: 0 6.4rem;
    }

    #page-cores-form main .schedule-item {
        display: grid;
        grid-template-columns: 1fr;
        column-gap: 1rem;
    }

    #page-cores-form main .schedule-item .input-block {
        margin-top: 0 !important;
    }

    #page-cores-form main footer {
        padding: 4rem 6.4rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    #page-cores-form main footer p {
        justify-content: space-between;
    }

    #page-cores-form main footer button {
        width: 20rem;
        margin-top: 0;
    }
}

.input-block {
    position: relative;
}

/*Pega os inputs a seguir*/
.input-block+.input-block {
    margin-top: 1.4rem;
}

.input-block label {
    font-size: 1.4rem;
}

.input-block input {
    width: 100%;
    height: 3.5rem;
    margin-top: 0.8rem;
    border-radius: 0.8rem;
    background: var(--color-input-background);
    border: 1px solid var(--color-line-in-white);
    outline: 0;
    padding: 0 1.6rem;
    font: 1.6rem 'Arial, Helvetica, sans-serif';
}

.input-block:focus-within::after {
    width: calc(100% - 3.2rem);
    height: 2px;
    content: "";
    background: var(--color-primary-light);
    position: absolute;
    left: 1.6rem;
    right: 1.6rem;
    bottom: 0;
}

.react-datetime-picker__wrapper {
    width: 100%;
    height: 3.5rem;
    margin-top: 0.8rem;
    border-radius: 0.8rem;
    background: var(--color-input-background);
    border: 0px solid var(--color-line-in-white);
    outline: 0;
    padding: 0 1.6rem;
    font: 1.6rem 'Arial, Helvetica, sans-serif';
}

.cabecalho {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
    gap: 10px;
}

.marginTopBottom {
    margin-bottom: 12px;
}

.mensagem-modal {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    margin-top: -190px;
    margin-left: 60px;
}

.mensagem-modal button {
    width: 40px;
    height: 3rem;
    background: var(--color-primary);
    color: var(--color-button-text);
    border: 0;
    border-radius: 0.8rem;
    cursor: pointer;
    font: 700 1.9rem;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    transition: background-color 0.2s;
    margin-top: 3.2rem;
}